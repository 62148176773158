<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-card-title>
        <v-icon>mdi-chart-multiple</v-icon>
        BB-Sakin Hesap Ekstre Raporu
      </v-card-title>

      <v-card-text v-if="clusterId">
        <v-form
          @submit.prevent="handleFormSubmit"
          ref="form"
          :disabled="zipTotalItems !== null"
          v-if="!zipFormEnabled"
        >
          <v-row dense>
            <v-col sm="2">
              <rs-datepicker
                label="Başlangıç Tarihi"
                v-model="formData.date.min"
                :rules="[rules.maxDate(formData.date.min, formData.date.max)]"
                required
              />
            </v-col>

            <v-col sm="2">
              <rs-datepicker
                label="Bitiş Tarihi"
                v-model="formData.date.max"
                :rules="[rules.minDate(formData.date.max, formData.date.min)]"
                required
              />
            </v-col>

            <v-col sm="2">
              <rs-select-resident
                label="Kişi"
                v-model="formData.resident_id"
                :rules="[rules.required]"
                :cluster-id="clusterId"
                @change="formData.house_id = null"
              />
            </v-col>

            <v-col sm="2">
              <rs-select-house
                :label="formData.house_id ? 'Bağımsız Bölüm' : 'Tümü'"
                v-model="formData.house_id"
                :cluster-id="clusterId"
                :resident-id="formData.resident_id"
                :disabled="!formData.resident_id"
                clearable
              />
              <!-- :extra-params="{ house_user_is_active: true }" -->
            </v-col>

            <v-col sm="4">
              <v-btn
                :loading="isLoading"
                :disabled="isLoading"
                color="primary"
                type="submit"
                class="ms-3"
              >
                Göster
              </v-btn>
            </v-col>
          </v-row>

          <p class="mt-3 mb-0 text-h5">
            <a href="#" @click.prevent="handleDownloadAllClick">
              Tüm TYA için raporları almak için tıklayın.
            </a>
          </p>
        </v-form>

        <v-form
          @submit.prevent="handleZipFormSubmit()"
          ref="form"
          :disabled="zipTotalItems !== null"
          v-else
        >
          <v-row dense>
            <v-col sm="2">
              <rs-datepicker
                label="Başlangıç Tarihi"
                v-model="formData.date.min"
                :rules="[rules.maxDate(formData.date.min, formData.date.max)]"
                required
              />
            </v-col>

            <v-col sm="2">
              <rs-datepicker
                label="Bitiş Tarihi"
                v-model="formData.date.max"
                :rules="[rules.minDate(formData.date.max, formData.date.min)]"
                required
              />
            </v-col>

            <v-col sm="3">
              <rs-select-block
                label="Bloklar"
                v-model="formData.block_ids"
                multiple
              />
            </v-col>

            <v-col sm="4">
              <v-btn
                :loading="isLoading"
                :disabled="isLoading"
                color="primary"
                type="submit"
                class="ms-3"
              >
                İndir
              </v-btn>
            </v-col>
          </v-row>

          <p class="mt-3 mb-0 text-h5">
            <a href="#" @click.prevent="handleDownloadSingleClick">
              Sakin ve bağımsız bölüm seçerek rapor almak için tıklayın.
            </a>
          </p>
        </v-form>

        <v-progress-linear
          v-if="zipTotalItems !== null"
          :value="zipProgress / zipTotalItems / 100"
          color="pink"
          height="25"
          class="mt-5"
        >
          <template v-slot:default>
            <strong>{{ zipProgress }} / {{ zipTotalItems }}</strong>
          </template>
        </v-progress-linear>

        <p v-if="iframeUrl" class="mt-3 mb-0 text-h5">
          <a :href="iframeUrl" download="rapor.pdf" target="_blank">
            Raporu cihazınıza indirmek için tıklayın.
          </a>
        </p>
        <iframe
          class="mt-3"
          :src="iframeUrl"
          frameborder="0"
          v-if="iframeUrl"
          style="width: 100%; height: 140vh"
        ></iframe>
      </v-card-text>

      <v-card-text v-else>
        Raporu görebilmek için üst menüden toplu yaşam alanı seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { BlobWriter, HttpReader, ZipWriter } from "@zip.js/zip.js";

import { hasForm, hasDataTable } from "@/view/mixins";

export default {
  mixins: [hasForm, hasDataTable],
  computed: {
    ...mapGetters(["clusterId", "cluster", "token"]),
  },
  data() {
    const date = new Date();
    const min = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth() - 1, 1)
    );
    const max = new Date();

    return {
      iframeUrl: null,
      reportData: null,
      formData: {
        date: {
          min: min.toJSON().split("T")[0],
          max: max.toJSON().split("T")[0],
        },
        resident_id: null,
        house_id: null,
        block_ids: [],
      },
      zipTotalItems: null,
      zipProgress: null,
      zipFormEnabled: false,
    };
  },
  methods: {
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      if (this.formData.date.min > this.formData.date.max) {
        this.$toast.error("Başlangıç tarihi bitiş tarihinden önce olmalıdır");
        return;
      }

      if (!this.formData.house_id) {
        return this.handleZipFormSubmit(this.formData.resident_id);
      }

      const params = Object.assign(this.buildSearchParams(this.formData));
      this.iframeUrl = null;
      this.isLoading = true;

      return this.$api
        .query("reports/house-user-debt-list", { params })
        .then((response) => {
          const reportData = Object.assign({}, response.data.data);

          reportData.start_date = new Date(
            this.formData.date.min
          ).toLocaleDateString();
          reportData.end_date = new Date(
            this.formData.date.max
          ).toLocaleDateString();
          if (reportData.resident.phone) {
            reportData.resident.phone = reportData.resident.phone.replace(
              /[^0-9+]/g,
              ""
            );
          }

          for (const index in reportData.totals) {
            reportData.totals[index] = this.numberToLocaleFormat(
              reportData.totals[index]
            );
          }

          for (const index in reportData.income_types) {
            reportData.income_types[index].debt = this.numberToLocaleFormat(
              reportData.income_types[index].debt
            );
            reportData.income_types[index].due = this.numberToLocaleFormat(
              reportData.income_types[index].due
            );
            reportData.income_types[index].diff = this.numberToLocaleFormat(
              reportData.income_types[index].diff
            );
          }

          for (const index in reportData.list) {
            const row = reportData.list[index];

            if (row.issues_on) {
              row.issues_on = new Date(row.issues_on).toLocaleDateString();
            }

            if (row.last_payment_on) {
              row.last_payment_on = new Date(
                row.last_payment_on
              ).toLocaleDateString();
            }

            if (row.amount_to_pay) {
              row.amount_to_pay = this.numberToLocaleFormat(row.amount_to_pay);
            }

            if (row.amount_paid) {
              row.amount_paid = this.numberToLocaleFormat(row.amount_paid);
            }

            if (row.deferment_amount) {
              row.deferment_amount = this.numberToLocaleFormat(
                row.deferment_amount
              );
            }

            if (row.balance) {
              row.balance = this.numberToLocaleFormat(row.balance);
            }

            if (row.deferment_interest_percent) {
              row.deferment = "%" + row.deferment_interest_percent;
              if (row.deferment_calculation_period === "monthly") {
                row.deferment += "/aylık";
              } else {
                row.deferment += "/günlük";
              }
            }
          }

          return reportData;
        })
        .then((reportData) => {
          let fileName = reportData.resident.name + "-";
          fileName += reportData.house_user.house_user_type.name + "-";
          fileName += reportData.house.block.name + "-";
          fileName += reportData.house.door_number;

          const params = {
            file_type: "pdf",
            print_template: "AccountStatement",
            receiptData: reportData,
            file_name: fileName,
          };

          return this.$api.post("print-file", params).then((response) => {
            this.iframeUrl = response.data.data.url;
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleZipFormSubmit(residentId) {
      if (this.zipTotalItems !== null) {
        return false;
      }

      this.iframeUrl = null;

      const params = {
        per_page: 1000,
      };

      if (this.formData.block_ids.length) {
        params.block_ids = this.formData.block_ids;
      }

      if (residentId) {
        params.resident_id = residentId;
      }

      this.isLoading = true;

      this.$api
        .query(`clusters/${this.clusterId}/house-users`, { params })
        .then((response) => {
          const list = [];

          for (const index in response.data.data) {
            list.push({
              house_id: response.data.data[index].house_id,
              resident_id: response.data.data[index].resident_id,
            });
          }

          this.zipTotalItems = list.length;
          this.zipProgress = 0;

          return list;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .then((list) => {
          const worker = new Worker("/workers/AccountStatementWorker.js", {
            type: "classic",
          });

          worker.onmessage = (message) => {
            if (message.data.urlList) {
              this.createZipFromUrlList(message.data.urlList);
            } else if (message.data.progress) {
              this.zipProgress++;
            }
          };

          // send a message to the worker
          worker.postMessage({
            method: "zipAll",
            data: {
              token: this.token,
              clusterId: this.clusterId,
              list: list,
              reportParams: Object.assign({}, this.formData),
            },
          });

          this.$toast.info(
            list.length + " BB-sakin ikilisi için rapor hazırlanmaya başlandı.",
            { timeout: 10000 }
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createZipFromUrlList(urlList) {
      const zipWriter = new ZipWriter(new BlobWriter("application/zip"));
      const promiseList = [];

      for (const index in urlList) {
        const url = new URL(urlList[index]);
        const arr = url.pathname.split("/");
        const name = arr[arr.length - 1];

        try {
          zipWriter.add(
            name,
            new HttpReader(urlList[index], {
              preventHeadRequest: true,
            })
          );
        } catch (e) {
          // eslint-disable-next-line
          console.error(e, url, urlList[index], arr, name);
        }
      }

      Promise.all(promiseList)
        .then(() => {
          return zipWriter.close();
        })
        .then((blob) => {
          const a = document.createElement("a");
          a.download = "HesapEkstre.zip";
          a.href = URL.createObjectURL(blob);
          document.body.appendChild(a);
          a.click();
        })
        .finally(() => {
          this.zipTotalItems = null;
          this.zipProgress = null;
        });
    },
    handleDownloadSingleClick() {
      this.zipTotalItems = null;
      this.zipProgress = null;
      this.iframeUrl = null;
      this.zipFormEnabled = false;
    },
    handleDownloadAllClick() {
      this.zipTotalItems = null;
      this.zipProgress = null;
      this.iframeUrl = null;
      this.zipFormEnabled = true;
    },
  },
};
</script>
